import { Grid } from '@achieve/ascend'
import { MediaImageStandard, Typography } from 'components/Contentful'
import Section from 'components/Section'
import { get as _get } from 'lodash-es'
import styles from './HomeHeroArticle.module.scss'
import { Eyebrow } from 'components/Eyebrow'
import { AchieveLink } from 'components/AchieveLink'
import { getSlug, normalizeCategoryName } from 'utils/conversions'
import { contentMap } from 'utils/contentful'
import { Home, Search } from 'react-feather'
import { useRouter } from 'next/router'
import { Breadcrumbs, InputAdornment, OutlinedInput } from '@mui/material'
import { AUTHORS_ROUTE, LEARN_ROUTE } from 'constants/navigation'
import { useContext, useReducer } from 'react'
import { ArticlesFilterContext } from 'providers/ArticlesFilterProvider'
import { Carousel } from 'components/Carousels'
import { normalizeContent } from 'utils/shared/hacks'
import { AuthorAvatar } from 'components/AuthorAvatar'
import { Image } from 'components/Image'

function ArticleCard({ item, index }) {
  const { title, hook, link, primaryCategory, mainImage, author } = item
  return (
    <AchieveLink
      href={link}
      track={{
        list_name: `ARTICLE LINK`,
        position: index,
        click_id: `Achieve Web | ${title}`,
        click_text: title,
        click_url: link,
      }}
      className={styles['anchor-links']}
    >
      <div className={styles['article-card-container']}>
        <div className={styles['img-container']}>
          <div>
            <MediaImageStandard
              className={styles['article-card-image']}
              alt={mainImage?.mediaAltText}
              content={mainImage?.mediaContent}
              layout="fill"
              objectFit="fill"
              aspectRatio={2 / 1}
              priority={true}
            />
          </div>
        </div>
        <div className={styles['info-container']}>
          <Eyebrow
            className={styles['main-card-eyebrow']}
            content={primaryCategory}
            component="h3"
          />
          <Typography variant="headingMd" content={title} component="h1" />
          <Typography variant="bodySm" content={hook} />
          <AuthorAvatar
            authorPhoto={author?.authorPhoto}
            index={0}
            href={`${AUTHORS_ROUTE}/` + (author?.slug || getSlug(author?.authorName))}
            authorName={author?.authorName}
          />
        </div>
      </div>
    </AchieveLink>
  )
}

//TODO: Enable search 2024/08/29 Content Refactor Update.
const searchEnabled = false

const HomeHeroArticle = ({ content }) => {
  const router = useRouter()
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(ArticlesFilterContext)

  const reducerFunction = (state, action) => {
    switch (action.type) {
      case 'setData':
        return { ...state, ...action.payload }
      default:
        return state
    }
  }

  const readContentData = (content) => {
    const mainListArticlesCategory = _get(content, 'fields.mainListArticlesCategory')
    if (mainListArticlesCategory) {
      const articles = _get(mainListArticlesCategory, 'relatedContentCollection.items')
      const { listTitle } = contentMap(
        normalizeContent(mainListArticlesCategory?.sectionContentsCollection?.items),
        ['listTitle']
      )
      return {
        relatedArticles: articles,
        listTitle,
        mainItem: _get(articles, '[0]'),
      }
    } else {
      const { listTitle } = contentMap(normalizeContent(_get(content, 'fields.sectionContents')), [
        'listTitle',
      ])
      return {
        relatedArticles: _get(content, 'fields.relatedContentCollection.items'),
        listTitle,
        mainItem: _get(content, 'fields.relatedContentCollection.items[0]'),
      }
    }
  }
  const data = readContentData(content)

  const [{ listTitle, mainItem, primaryCategory, relatedArticles }, dispatchLocal] = useReducer(
    reducerFunction,
    {
      listTitle: data.listTitle,
      mainItem: data.mainItem,
      primaryCategory: normalizeCategoryName(router?.query?.categoryname ?? ''),
      relatedArticles: data.relatedArticles,
    }
  )

  const setMainItem = (item) => [
    dispatchLocal({
      type: 'setData',
      payload: {
        mainItem: item,
      },
    }),
  ]

  const renderReleatedArticles = () => {
    return relatedArticles.map((fields, index) => {
      const isVideo = fields.mainImage.__typename === 'VideoPlayer'
      const alt = isVideo ? fields?.mainImage?.altText : fields?.mainImage?.mediaAltText
      const img = isVideo ? fields?.mainImage?.thumbnail : fields?.mainImage?.mediaContent

      return (
        <div
          className={styles['releated-card-item-container']}
          key={`releatedItems-${index}`}
          onClick={() => setMainItem(fields)}
          data-active={fields.slug === mainItem?.slug}
        >
          <div className={styles['releated-card-item-image']}>
            <MediaImageStandard
              className={styles['main-card-image']}
              alt={alt}
              content={img}
              layout="fixed"
              objectFit="cover"
              height={70}
              width={70}
              priority={true}
              data-testid="releated-card-item-image"
            />
            {isVideo && (
              <div className={styles['play-button']}>
                <Image alt={'Play Button'} height={40} src="/play-btn.svg" width={40} />
              </div>
            )}
          </div>
          <Typography
            className={styles['main-card-title']}
            variant="bodySm"
            content={fields?.title}
            data-testid="releated-card-item-title"
          />
        </div>
      )
    })
  }

  return (
    <Section className={styles['main-section']} data-testid="article-hero-section">
      <Grid container xs={12} marginTop={2}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={9}
            className={styles['breadcrumb-container']}
            data-testid="breadcrumb-container"
          >
            <Breadcrumbs aria-label="breadcrumb" className={styles['breadcrumb']}>
              <AchieveLink
                underline="hover"
                color="inherit"
                href="/"
                track={{
                  list_name: 'Breadcrumb',
                  click_text: `Home Page`,
                  click_id: `Home Page`,
                }}
                data-testid="breadcrumb-home-link"
              >
                <Home size={14} />
              </AchieveLink>
              <AchieveLink
                href={`${LEARN_ROUTE}`}
                track={{
                  list_name: 'Breadcrumb',
                  click_text: 'Learn',
                  click_id: 'Learn',
                }}
                underline="hover"
                color="inherit"
                data-testid="breadcrumb-learn-link"
              >
                <Typography
                  variant="bodyXs"
                  content={'Learn'}
                  data-testid="breadcrumb-learn-link-text"
                />
              </AchieveLink>
              {primaryCategory &&
                (getSlug(primaryCategory) === router?.query?.categoryname ? (
                  <Typography
                    variant="bodyXs"
                    content={primaryCategory}
                    data-testid="breadcrumb-primary-category-link-text"
                  />
                ) : (
                  <AchieveLink
                    href={`${router.pathname.split('[')?.[0]}${router?.query?.categoryname}`}
                    track={{
                      list_name: 'Breadcrumb',
                      click_text: `${primaryCategory}`,
                      click_id: `${primaryCategory}`,
                    }}
                    underline="hover"
                    color="inherit"
                    data-testid="breadcrumb-primary-category-link"
                  >
                    {`${primaryCategory}`}
                  </AchieveLink>
                ))}
            </Breadcrumbs>
          </Grid>
          {searchEnabled && (
            <Grid item xs={12} lg={3} className={styles['hero-search-input']}>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">
                    <Search size={16} />
                  </InputAdornment>
                }
                size="small"
                fullWidth
                onBlur={(e) => {
                  dispatch({ type: 'SET_KEYWORDS', payload: e.target.value })
                }}
                placeholder="Search"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {relatedArticles?.length > 0 && (
        <Grid
          container
          spacing={2}
          marginTop={1}
          className={styles['hero-section-desktop']}
          data-testid="article-hero-container-desktop"
        >
          <Grid item xs={12} lg={9} className={styles['main-card-container']}>
            <div
              className={styles['main-card-image-container']}
              data-testid="article-hero-image-container"
            >
              <MediaImageStandard
                className={styles['main-card-image']}
                alt={
                  mainItem.mainImage.__typename === 'VideoPlayer'
                    ? mainItem?.mainImage?.altText
                    : mainItem?.mainImage?.mediaAltText
                }
                content={
                  mainItem.mainImage.__typename === 'VideoPlayer'
                    ? mainItem?.mainImage?.thumbnail
                    : mainItem?.mainImage?.mediaContent
                }
                data-testid="article-hero-image"
                layout="fill"
                objectFit="fill"
                priority={true}
                aspectRatio={2 / 1}
              />
              {mainItem?.mainImage.__typename === 'VideoPlayer' && (
                <div className={styles['main-play-button']}>
                  <Image alt={'Play Button'} height={64} src="/play-btn.svg" width={64} />
                </div>
              )}
            </div>
            <AchieveLink
              className={styles['eyebrow-link']}
              href={`${LEARN_ROUTE}/${getSlug(mainItem?.primaryCategory)}`}
              data-testid="article-hero-eyebrow-link"
            >
              <Eyebrow
                className={styles['main-card-eyebrow']}
                content={mainItem?.primaryCategory}
                component="h3"
                data-testid="article-hero-eyebrow-link-text"
              />
            </AchieveLink>
            <AchieveLink
              key={`releatedItems`}
              track={{
                list_name: 'Card Article',
                click_position: 0,
                click_id: mainItem?.title,
                click_text: mainItem?.title,
              }}
              className={styles['anchor-links']}
              href={`${LEARN_ROUTE}/${getSlug(mainItem?.primaryCategory)}/${mainItem?.slug}`}
              data-testid="article-hero-title-link"
            >
              <Typography
                variant="headingLg"
                content={mainItem?.title}
                component="h1"
                data-testid="article-hero-title-link-text"
              />
            </AchieveLink>
            {mainItem?.hook && (
              <Typography
                variant="bodySm"
                content={mainItem?.hook}
                data-testid="article-hero-hook"
              />
            )}
            {mainItem?.author?.authorName && (
              <AuthorAvatar
                authorPhoto={mainItem?.author?.authorPhoto}
                index={0}
                href={
                  `${AUTHORS_ROUTE}/` +
                  (mainItem?.author?.slug || getSlug(mainItem?.author?.authorName))
                }
                authorName={mainItem?.author?.authorName}
                priority={true}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={3} data-testid="article-hero-related-card-container">
            <div className={styles['releated-card-container']}>
              <Typography
                variant="displaySm"
                content={listTitle?.textContent}
                data-testid="related-card-title-text"
              />
              <div className={styles['releated-card-list']}>{renderReleatedArticles()}</div>
            </div>
          </Grid>
        </Grid>
      )}
      {relatedArticles?.length > 0 && (
        <Grid
          container
          spacing={2}
          marginTop={2}
          className={styles['hero-section-mobile']}
          data-testid="article-hero-container-mobile"
        >
          <Carousel
            carouselItems={relatedArticles}
            className={styles['articles-carousel-wrapper']}
            classNameContainer={styles['articles-carousel-container']}
            classNameItems={styles['articles-carousel-items']}
            itemComponent={ArticleCard}
            variant="auto"
            idPrefix={'hero-article-list'}
            // disableArrows={isSmallerXL || carouselEnabledByItemCount ? false : true}
            withIndicators={true}
            classNameControls={styles['carousel-wrapper-controls']}
          />
        </Grid>
      )}
    </Section>
  )
}

export { HomeHeroArticle }
export default HomeHeroArticle
